body {
  margin: 0;
  font-family: Roboto,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif;
  font-size: .812rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888da8;
}

a:hover {
  text-decoration: none;
}

p {
  text-align: justify;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.medium-padding50 {
  padding: 50px 0;
}

.btn-primary {
  color: #fff;
  background-color: #ff5e3a;
  border-color: #ff5e3a;
}

.white-popup-block {
  background-color: #FFF !important;
}

.with-title-decoration .heading-title {
  position: relative;
  padding-bottom: 18px;
}

button.dropdown-toggle,
input.form-control {
  background-color: #FFF !important;
}

.color-red {
  fill: #ff5e3a !important;
  color: #ff5e3a !important;
}

.badge {
  display: inline-block;
  padding: 0.55em .9em;
  font-size: 75%;
  text-align: center;
  vertical-align: baseline;
  border-radius: .25rem;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

